import React from 'react';
import Header from '../Shared/Components/Header/Header';
import './About.scss';
import Hero from '../../Assets/Images/About/turbines.jpg';
import HeroMob from '../../Assets/Images/About/turbines-mob.jpg';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import Forest from '../../Assets/Images/About/1.jpg';
import TenYears from '../../Assets/Images/About/next10years.jpg';
import BetterBusinessAct from '../../Assets/Images/OurPartners/betterbusinessact.png';
import Finance from '../../Assets/Images/About/finance.jpg';
import ScrollingBanner from '../Shared/Components/ScrollingBanner/ScrollingBanner';
import TextRow from './../Shared/Components/TextRow/TextRow';
import AboutIntro from './Components/AboutIntro';
import Eco from '../../Assets/Images/About/sustainable-solutions.jpg';
import ImageRow from './../Shared/Components/ImageRow/ImageRow';
import { Helmet } from "react-helmet";
import Support from './Components/Support/Support';
import TerraCarta from '../../Assets/Images/OurPartners/TC Logo.jpg'

const About = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | Educating in Sustainability</title>
                <meta name="description" content="Created in 2020, developed over a lifetime. We aim to engage people of all ages on sustainability" />
            </Helmet>

            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                title="Created in 2020"
                lineText="About Us"
                subtitle="Developed over a lifetime"
            />

            <AboutIntro />

            <TextRow
                divClass="bgNavy"
                animate={true}
                lineText="Taking action"
                title="Our Commitment"
                paragraph="In this same spirit, we are amongst the 550 businesses in the UK who have publicly supported the Better Business Act and will continue to ensure that we are doing all we can to raise the level of expectation amongst businesses in order to create a more sustainable future for all."
            />

            <Support
                lineText="Collaborating for Greater Impact"
                title="We Support"
                paragraph="We are proud to support the Better Business Act and the Terra Carta  from HRH The Prince of Wales’ Sustainable Markets Initiative. The Terra Carta outlines ten areas for action and comprises of nearly 100 actions for business as the basis of a recovery plan that puts Nature, People and Planet at the heart of global value creation."
                animate={true}
                image1={BetterBusinessAct}
                url1="https://betterbusinessact.org"
                image2={TerraCarta}
                url2="https://www.sustainable-markets.org/terra-carta"
            />

            <TextImageRow
                lineText="our focus for the next 10 years"
                title="Committing to sustainable solutions"
                paragraph1="We offer an e-learning solution to organisations across the globe which carries an environmental benefit with participants being able to complete their studies in a place convenient to them, reducing the need to travel to a classroom. However, providing the servers to host a digital environment like this can sometimes be inefficient, so as a business we had to think about how we could best host our services whilst minimising the impact on the environment."
                paragraph2="With that in mind, we made the decision that our website and online platform would be hosted through Microsoft Azure cloud services. This was a conscious decision given Microsoft's own sustainability ambitions to be Carbon Negative by 2030. By hosting our platforms on cloud servers we are reducing the carbon emissions that our business will produce given the efficiency that the cloud provides "
                image={Eco}
                rowReverse={false}
                animate={true}
                divClass="bgGrey"
            />

            <ImageRow
                image={Forest}
                imageMob={Forest}
                divClass="get-in-touch"
                lineText="Understanding The SDGs"
                title="What are the benefits?"
                paragraph="The benefits are clear as citizens of the planet. To eradicate poverty and inequality would change the lives of so many around the world. To reverse the impact of climate change and ensure we are doing all we can to preserve the unique biodiversity of the planet will be a gift that future generations will thank us for."
            >
            </ImageRow>

            <TextImageRow
                lineText="Sustainable finance"
                title="Open up opportunities to access sustainable finance"
                paragraph1="As well as the social and environmental benefits, there are also economic benefits to businesses including but not limited to, the rising opportunities in sustainable finance. By working with The Sustainability Academy, we can help your organisation become 'sustainable finance ready' and open the door to growth opportunities that may currently sit just out of reach."
                image={Finance}
                rowReverse={true}
                animate={true}
                divClass="bgGrey"
            />

            <TextImageRow
                lineText="our focus for the next 10 years"
                title="One World 17 Goals"
                paragraph1="The 17 SDGs provide a framework to put our world on the path to a more sustainable future and The Sustainability Academy helps you to engage and educate your organisation to enable you to contribute to the successful implementation of the SDGs. Every organisation has to contribute to this effort and The Sustainability Academy is no different. We are committed to embedding the SDGs throughout our organisation."
                image={TenYears}
                rowReverse={false}
                animate={true}
            />

            <ScrollingBanner
                lineText="Our Clients"
                title="Partners"
                align="center"
                bgColor="#f7f7f7"
            />
        </>
    );
};

export default About;