export const Items = [
    {
        Icon: "consulting",
        Title: "Bespoke Training Packages",
        Description: "The Sustainability Academy works with a range of affiliated partners who help us to create specialist training packages to suit your organisation such as leadership and management and digital technologies."
    },
    {
        Icon: "layers",
        Title: "Bespoke Sustainability Support",
        Description: "We have a range of professionals and speakers who can provide support for your business and help to create bespoke sustainability training packages aligned to your sustainability strategy."
    },
    {
        Icon: "grow",
        Title: "Counselling & Mindfulness",
        Description: "We have affiliated practitioners who can provide this specialist service."
    },
    {
        Icon: "stats-up",
        Title: "Environmental Social & Governance Support",
        Description: "Support to enhance your Environmental Social and Governance rating."
    },
    {
        Icon: "customer",
        Title: "Coaching & Mentoring",
        Description: "We are very proud to be able to recommend best in class coaching and mentoring services."
    },
    {
        Icon: "video",
        Title: "Animation",
        Description: "There is no better way to promote your business than by storytelling we are delighted to be able to offer this service."
    }
];
