import React from 'react';
import Header from './../Shared/Components/Header/Header';
import Hero from '../../Assets/Images/ContactUs/contact.jpg';
import HeroMob from '../../Assets/Images/ContactUs/contact.jpg';
import ContactForm from './Components/ContactForm';
import TextRow from './../Shared/Components/TextRow/TextRow';
import ContactMethods from './Components/ContactMethods';
import { Helmet } from "react-helmet";

const ContactUs = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | Talk about the SDGs</title>
                <meta name="description" content="If you want to join our journey to help create sustainable future then we would love to talk " />

            </Helmet>

            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Hello"
                title="Contact Us"
                subtitle="Let's see if we can help" />

            <TextRow
                title="Get In Touch"
                lineText="We are here to help"
                paragraph="If you think we can help your organisation to enhance your colleagues understand your responsibility to become more sustainable and support the global challenge then we would love to talk."
            >
                <ContactMethods />
                <ContactForm />
            </TextRow>
        </>
    );
};

export default ContactUs;