import React from 'react';
import './List.scss';
import ListItem from './ListItem';

const List = ({ items }) => {
    return (
        <section className={`row no-gutters list`}>
            <div className="container-xxl container-ac">
                <div className={`list-container`}>
                    <div className="text wrap py-5 col-12">
                        {items.map((i, j) => (
                            <ListItem
                                key={j}
                                icon={i.Icon}
                                title={i.Title}
                                description={i.Description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default List;