import React from 'react';
import './Hero.scss';
import HeroImage from '../../../Assets/Images/Home/sea-farm.jpg';
import HeroMob from '../../../Assets/Images/Home/sea-farm.jpg';
import { isMobile } from 'react-device-detect';
import SALogo from '../../../Assets/Images/SALogoWhite.png';
import LineText from '../../Shared/Components/LineText/LineText';

const Hero = () => {
    return (
        <header className="row no-gutters hero fadeInDelay animated">
            <div className="overlay"></div>
            <div className="hero-img p-5 " style={{
                backgroundImage: isMobile ? `url(${HeroMob})` : `url(${HeroImage
                    })`
            }}></div>
            <div className="container-xxl hero-text">
                {/* <img src={SALogo} alt="The Sustainability Academy" width="150px" className="pb-5" /> */}
                <LineText lineText="The Sustainability Academy" />
                <div className="block">
                    <h1>Educating the way to a sustainable future</h1>
                </div>
                <p>Understand the importance of the 17 Sustainable Development Goals and how to implement them</p>
            </div>
        </header>
    );
};

export default Hero;