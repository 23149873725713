import React from 'react';
import Hero from './Components/Hero';
import WhyUs from './Components/WhyUs';
import './Home.scss';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import Eco from '../../Assets/Images/Home/eco.png';
import GoGoals from '../../Assets/Images/Home/GoGoals.jpeg';
import TextRow from '../Shared/Components/TextRow/TextRow';
import Testimonials from '../Shared/Components/Testimonials/Testimonials';
import ScrollingBanner from './../Shared/Components/ScrollingBanner/ScrollingBanner';
import FeaturedCourses from './Components/FeaturedCourses';
import List from './Components/List';
import GetInTouch from '../Shared/Components/GetInTouch/GetInTouch';
import SDGs from '../Shared/Components/SDGs/SDGs';
import Video from '../Shared/Components/Video/Video';
import Animation from '../../Assets/Video/sdg-video.mp4';
import Forest from '../../Assets/Images/Home/forest.jpg';
import { Items } from './Components/ListData';
import { Helmet } from "react-helmet";
import { TestimonialArray } from './Components/TestimonialData';
import VideoYouTube from '../Shared/Components/Video/VideoYouTube';
import ThamesYouTube from '../../Assets/Images/Home/ThamesYouTube.jpg';
import IntroSDGs from '../../Assets/Images/Home/IntroSDGs.jpg';

const Home = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | Training for the Sustainable Development Goals </title>
                <meta name="description" content="At The Sustainability Academy our aim is to educate the way to a sustainable future" />
            </Helmet>

            <Hero />

            <TextImageRow
                title="With less than 10 years to go to implement the goals the time to act is now"
                lineText="Committed To Sustainability"
                paragraph1="Businesses are uniquely placed to be able to change their activities to have a positive impact on the planet as well as achieving commercial success. However, with problems as big as climate change or no poverty, it can be difficult to know how one person can make a change. The Sustainability Academy will show you how to make that change."
                image={Eco}
                divClass="intro"
                buttonText="About Us"
                buttonUrl="/About"
                animate={true}
            />

            <VideoYouTube
                image={ThamesYouTube}
                video='https://youtu.be/d8wBhTxzchA'
                animate={true}
                lineText="Feedback"
                title="What is our impact?"
                paragraph="Thames Water gave us their thoughts on our SDG Diploma"
            />

            <TextImageRow
                title="Engaging people of all ages to support humanity’s greatest fight"
                lineText="Implementing the SDGs"
                paragraph1="Ensuring that people understand and interact with the SDGs means finding different ways of engaging with them to make sure we are doing all we can to achieve them. Thats why we’ve partnered with United Nations Regional Information Centre (UNRIC) to embed their Go! Goals game as part of our KidsSDGs programme."
                image={GoGoals}
                divClass="intro"
                animate={true}
            />

            <Video
                image={Forest}
                video={Animation}
                animate={true}
                lineText="The Sustainability Academy"
                title="This is what we do"
                paragraph="Leave no one behind"
            />

            <SDGs />

            <VideoYouTube
                image={IntroSDGs}
                video='https://youtu.be/BDorYfOQTzc'
                animate={true}
                lineText="Introducing the SDGs"
                title="How do the SDGs connect to each other?"
                paragraph="What, Why and When?"
            />

            <TextRow
                lineText="Sustainable Development Goals"
                title="Why are they important?"
                paragraph="The goals are a clear action plan to set the planet on a more sustainable path to a future where inequalities are reduced. The planet is supported to recover from the loss of resources it has suffered from increased human activity and future generations can grow up in a world that is no longer on a collision course with disaster."
                divClass="bgNavy"
                animate={false}>
                <div className="row">
                    <div className="text-center col-lg-10 offset-lg-1">
                        <p style={{ fontWeight: 800 }}>and...</p>
                        <p style={{ fontWeight: 700, color: "#fbb638", fontSize: "20px" }}>Unless drastic action is taken and the speed of the implementation of the goals quickens then the scale of the problems that we are facing cannot be overstated.</p>
                    </div>
                </div>
            </TextRow>

            <FeaturedCourses />

            <WhyUs />

            <Testimonials
                divClass="testimonials bgNavy"
                lineText="Our Clients"
                title="Testimonials"
                paragraph="Don't take our word for it, we are already making a difference, this is what some of our current clients are saying"
                data={TestimonialArray}
            />

            <List items={Items} />

            <GetInTouch />

            <ScrollingBanner
                lineText="Our Clients"
                title="Partners"
                text=""
                align="center"
            />
        </>
    );
};

export default Home;