import React from 'react';
import Hero from '../../Assets/Images/Training/books.jpg';
import HeroMob from '../../Assets/Images/Training/books-mob.jpg';
import Header from '../Shared/Components/Header/Header';
import Programmes from './Components/Programmes/Programmes';
import TextRow from './../Shared/Components/TextRow/TextRow';
import { Helmet } from "react-helmet";
import ScrollingBanner from '../Shared/Components/ScrollingBanner/ScrollingBanner';
import { TestimonialArray } from './Components/TestimonialData';
import CourseTestimonials from './Components/CourseTestimonials/CourseTestimonials';

const BespokeTraining = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | Sustainability Training</title>
                <meta name="description" content="The SDGs will only be successful if all people are engaged and educated on them" />
            </Helmet>

            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Solutions"
                title="Our Courses"
                subtitle="Helping your business to grow sustainably" />

            <TextRow
                title="SDGs for your organisation"
                lineText="We are here to help embed sustainability"
                divClass="bgGrey"
                paragraph="The SDGs provide the framework for the sustainability strategies of most organisations. Through CSR initiatives and annual reports, organisations can demonstrate a broad understanding of the SDGs but without engagement of the entire colleague population, no organisation can hope to embed SDGs into every day decision making which will limit the potential of the goals being successfully implemented by 2030. By working with The Sustainability Academy, your organisation can engage all colleagues on the SDGs, helping them to understand how they can play their part in a global movement."
            />

            <TextRow
                divClass="bgNavy"
                animate={true}
                lineText="helping your colleagues to help your organisation make a difference"
                title="Sustainable Development Goals Diploma"
            >
                <div className="row">
                    <div className="text-center col-lg-10 offset-lg-1">
                        <p style={{ fontSize: "17px" }}><strong>The Diploma in SDGs</strong> is an ideal solution to embed into <strong>any organisation</strong> private or public sector and fits perfectly into a <strong>mandatory onboarding suite</strong> and <strong>CPD training for all staff</strong> and can be evidenced in your company <strong>ESG reports</strong>.</p>
                        <p style={{ fontSize: "17px" }}>The Diploma provides <strong>18 hours of learning</strong> and can help raise awareness and understanding of the global challenge for <strong>any age group</strong>. It is an <strong>excellent addition</strong> to embed for <strong>Apprenticeships, Kickstart Programme, Restart programme, Sector based Work Academies</strong> and <strong>employability programmes</strong>.</p>
                    </div>
                </div>
            </TextRow>

            <Programmes />

            <CourseTestimonials
                divClass="bgNavy courseTestimonials"
                lineText="Testimonials"
                title="Education with impact"
                paragraph="Here is a snapshot of what our candidates are saying"
                data={TestimonialArray}
            />

            <ScrollingBanner />

        </>
    );
};

export default BespokeTraining;