import React from 'react';
import Header from '../Shared/Components/Header/Header';
import Hero from '../../Assets/Images/OurPartners/hands.jpg';
import HeroMob from '../../Assets/Images/OurPartners/hands-mob.jpg';
import './OurSupporters.scss';
import Innovateher from '../../Assets/Images/OurPartners/innovateher.png';
import E2M from '../../Assets/Images/OurPartners/e2m.jpg';
import Whatif from '../../Assets/Images/OurPartners/WhatIf.png';
import Grey4Gold from '../../Assets/Images/OurPartners/grey4gold.png';
import Abe from '../../Assets/Images/OurPartners/abeuk.jpg';
import Handshake from '../../Assets/Images/OurPartners/hands.jpg';
import ImageRow from '../Shared/Components/ImageRow/ImageRow';
import PartnersRow from './Components/PartnersRow';
import PartnersIntro from '../Shared/Components/TextRow/TextRow';
import { Helmet } from "react-helmet";
import UNRIC from '../../Assets/Images/OurPartners/unric-logo-1.png';

const OurSupporters = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | SDG Supporters</title>
                <meta name="description" content="Only by working together can we make the impact that is needed to create a sustainable future" />
            </Helmet>

            <Header
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="Our Affiliations"
                title="Our Supporters"
                subtitle="We all need a little help from our friends" />

            <PartnersIntro
                lineText="The Sustainability Academy"
                title="Our Affiliate Partners"
                paragraph="This is a global challenge and we have many partners who support the SDG framework within their organisations"
                divClass=""
            />

            <PartnersRow
                lineText="UNRIC"
                title="UNRIC"
                paragraph={`It's never too soon to learn about protecting our planet and people who live here. The Go Goals Game is an entertaining introduction to sustainable development suitable for anyone from 7 years old upwards. It is also a good start for a group discussion on sustainability and actions that can be implemented at school or at home"`}
                image={UNRIC}
                divClass="no-border-img"
                rowReverse={false}
                animate={true}
                link="https://www.unric.org/"
                client="Fabienne Pompey, Chef du Desk, UNRIC"
            />

            {/* <PartnersRow
                lineText="Abe"
                title="Abe.com"
                paragraph="ABE has designed its theory of change model around the United Nations Sustainable Development Goals. Through education we aim to help foster an open, inclusive, global market economy where everyone benefits from responsible globalisation and trade. We offer learning products that are deliverable in all education settings, including fragile zones, and we actively target and support projects which engage girls and young women and learners in remote regions, and which promote sustainable development. Working in partnership with The Sustainability Academy, we can help organisations deliver a safer, healthier and more prosperous future for all"
                image={Abe}
                divClass="no-border-img"
                rowReverse={false}
                animate={true}
                link="https://www.abeuk.com/"
                client="Rob May CEO, ABE Global, Friend of UNESCO"
            /> */}

            <PartnersRow
                lineText="InnovateHer"
                title="InnovateHer"
                paragraph="“Our primary focus as an organisation is to improve gender equality within the tech sector where we have found that women represent just 19% of the workforce. This imbalance costs time and money as well as widening the digital skills gap. Only by improving gender equality can we create diversity of thought across organisations and ultimately create a more equitable society. We are proud to support SDG 5 – Gender Equality and will strive towards its successful implementation by 2030 as we continue our mission to get girls ready for the tech industry and the industry ready for girls”"
                image={Innovateher}
                divClass="no-border-img"
                rowReverse={true}
                animate={true}
                link="https://www.innovateher.co.uk"
                client="Chelsea Slater, D&amp;I expert and InnovateHer co-founder"
            />

            <PartnersRow
                lineText="Escape2Make"
                title="Escape2Make"
                paragraph="Escape2Make (E2M) is an educational charity which aims to connect all young people with their purpose, passions and like-minded people. This is achieved through a vast array of inspiring  programmes of workshops designed to create social and/or environmental impact in the community. Our mission is to be an off-line incubator for developing creativity, compassion and enterprising skills for 11-18’s. We improve young people’s wellbeing, life chances and resilience through involvement in creative and cultural activity, ultimately supporting them to become change makers in their community.  We are proud to be supporting SDG3, SDG4 and SDG8"
                image={E2M}
                divClass="no-border-img"
                rowReverse={false}
                animate={true}
                link="https://www.escape2make.org"
                client="Jenny Natusch Founder at EscapetoMake (E2M)"
            />

            <ImageRow
                image={Handshake}
                imageMob={Handshake}
                lineText="Working together towards a sustainable future"
                title="Collaboration"
                paragraph="We are always growing our partnerships and our story is ever evolving. If you think you or your organisation can help us to provide a service to our clients throughout the next 10 years, then we'd love to talk to you."
            />

            <PartnersRow
                lineText="What If..."
                title="We Do Real Estate Differently"
                paragraph="“It is time that Sustainable Development Goals became common language in the meeting rooms of councils and property investors to ensure a better future for our town centres and their communities. Goal 11 – Sustainable Cities and Communities, relies on councils and property owners ensuring that at least a proportion of profit is recycled into the communities and not all sucked out to the benefit of corporate shareholders. Reducing fractional ownership, public private partnerships and more long-term investment is the key to a better more sustainable future.”"
                image={Whatif}
                divClass="no-border-img partners"
                animate={true}
                rowReverse={true}
                link="https://www.whatifgroup.io"
                client="Paul Wright, real estate expert and What If Group co-founder"
            />

            <PartnersRow
                lineText="Rowland Gee"
                title="Grey4Gold"
                paragraph="It is now more important than ever for all organisations to embed the Sustainable Development Goals throughout the DNA of their businesses. Particularly prominent and a huge consideration for the retail industry is to consider SDG 12, Responsible Consumption and Production.  We must disseminate the message from the boardroom to all of our colleague populations and help to support the achievement of these goals and promote, sustainable, ethical supply chains."
                image={Grey4Gold}
                divClass="no-box-shadow"
                animate={true}
                link="https://grey4gold.com/"
                client="Rowland Gee, Former CEO Moss Bros and Founder Grey4Gold"
            />
        </>
    );
};

export default OurSupporters;