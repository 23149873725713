import React from 'react';
import LineText from '../LineText/LineText';
import './TextColumns.scss';

const TextColumns = ({ lineText, title, divClass, col1a, col1b, col2a, col2b, center, animated, children, subheading }) => {

    return (
        <section className={`row no-gutters pb-5 text-columns ${animated ? "fadeInUpDelayFast animated" : ""} ${divClass ? divClass : ""}`}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="row px-3 px-md-0 text-md-left columns">
                        <div className="col-12 col-md-6">
                            <p>{col1a}</p>
                            <p>{col1b}</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p>{col2a}</p>
                            <p>{col2b}</p>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </section>
    );
};

export default TextColumns;