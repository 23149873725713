import React from 'react';
import LineText from '../../../Shared/Components/LineText/LineText';
import { FadeInOnScroll } from '../../../Shared/Utilities/FadeInOnScroll';

const Support = ({ title, lineText, paragraph, animate, divClass, image1, image2, url1, url2 }) => {
    const [ref] = FadeInOnScroll();

    return (
        <section ref={animate ? ref : null} className={`row no-gutters row-pad ${divClass ? divClass : ""}`}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="col-12 col-lg-10 offset-lg-1 text-center">
                        <LineText lineText={lineText} center={true} />
                        <h2>{title}</h2>
                        <div className="pt-4">
                            {paragraph &&
                                <p style={{ fontSize: "17px" }}>{paragraph}</p>
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center py-5 align-items-center">
                        <div className="col-12 col-md-5 text-center mr-md-3" >
                            <a target="_blank" href={url1}>
                                <img src={image1} className="img-fluid" style={{ maxWidth: "300px" }} />
                            </a>
                        </div>
                        <div className="col-12 col-md-5 text-center ml-md-3 mt-5 mt-md-0">
                            <a target="_blank" href={url2}>
                                <img src={image2} className="img-fluid" style={{ maxWidth: "300px" }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Support;